import { CurrencyAmount, JSBI, Token, Trade } from 'uniswap-bsc-sdk'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowDown } from 'react-feather'
import { isMobile } from 'react-device-detect'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonError, ButtonImagePlus, ButtonLight, ButtonPrimary } from '../../components/Button'
import Card, { GreyCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { AutoRow, RowBetween } from '../../components/Row'
import AdvancedSwapDetailsDropdown from '../../components/swap/AdvancedSwapDetailsDropdown'
import BetterTradeLink from '../../components/swap/BetterTradeLink'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import { ArrowWrapper, BottomGrouping, Dots, SwapCallbackError, Wrapper } from '../../components/swap/styleds'
import TradePrice from '../../components/swap/TradePrice'
import TokenWarningModal from '../../components/TokenWarningModal'

import { BETTER_TRADE_LINK_THRESHOLD, INITIAL_ALLOWED_SLIPPAGE, PNDA } from '../../constants'
import { isTradeBetter } from '../../data/V1'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useToggledVersion, { Version } from '../../hooks/useToggledVersion'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { useToggleSettingsMenu, useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import {
	useDefaultsFromURLSearch,
	useDerivedSwapInfo,
	useSwapActionHandlers,
	useSwapState
} from '../../state/swap/hooks'
import { useExpertModeManager, useUserDeadline, useUserSlippageTolerance } from '../../state/user/hooks'
import { LinkStyledButton, TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import AppBody from '../AppBody'
import { ClickableText } from '../Pool/styleds'
import { addTokenToMetamask } from '../../utils/addTokenToMetamask'

export default function Swap() {
	const loadedUrlParams = useDefaultsFromURLSearch()

	// token warning stuff
	const [loadedInputCurrency, loadedOutputCurrency] = [
		useCurrency(loadedUrlParams?.inputCurrencyId),
		useCurrency(loadedUrlParams?.outputCurrencyId)
	]
	const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
	const urlLoadedTokens: Token[] = useMemo(
		() => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
		[loadedInputCurrency, loadedOutputCurrency]
	)
	const handleConfirmTokenWarning = useCallback(() => {
		setDismissTokenWarning(true)
	}, [])

	const { account } = useActiveWeb3React()
	const theme = useContext(ThemeContext)

	// toggle wallet when disconnected
	const toggleWalletModal = useWalletModalToggle()

	// for expert mode
	const toggleSettings = useToggleSettingsMenu()
	const [isExpertMode] = useExpertModeManager()

	// get custom setting values for user
	const [deadline] = useUserDeadline()
	const [allowedSlippage] = useUserSlippageTolerance()

	// swap state
	const { independentField, typedValue, recipient } = useSwapState()
	const {
		v1Trade,
		v2Trade,
		currencyBalances,
		parsedAmount,
		currencies,
		inputError: swapInputError
	} = useDerivedSwapInfo()
	const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
		currencies[Field.INPUT],
		currencies[Field.OUTPUT],
		typedValue
	)
	const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
	const toggledVersion = useToggledVersion()
	const trade = showWrap
		? undefined
		: {
				[Version.v1]: v1Trade,
				[Version.v2]: v2Trade
		  }[toggledVersion]

	const betterTradeLinkVersion: Version | undefined =
		toggledVersion === Version.v2 && isTradeBetter(v2Trade, v1Trade, BETTER_TRADE_LINK_THRESHOLD)
			? Version.v1
			: toggledVersion === Version.v1 && isTradeBetter(v1Trade, v2Trade)
			? Version.v2
			: undefined

	const parsedAmounts = showWrap
		? {
				[Field.INPUT]: parsedAmount,
				[Field.OUTPUT]: parsedAmount
		  }
		: {
				[Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
				[Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
		  }

	const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
	const isValid = !swapInputError
	const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

	const handleTypeInput = useCallback(
		(value: string) => {
			onUserInput(Field.INPUT, value)
		},
		[onUserInput]
	)
	const handleTypeOutput = useCallback(
		(value: string) => {
			onUserInput(Field.OUTPUT, value)
		},
		[onUserInput]
	)

	// modal and loading
	const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
		showConfirm: boolean
		tradeToConfirm: Trade | undefined
		attemptingTxn: boolean
		swapErrorMessage: string | undefined
		txHash: string | undefined
	}>({
		showConfirm: false,
		tradeToConfirm: undefined,
		attemptingTxn: false,
		swapErrorMessage: undefined,
		txHash: undefined
	})

	const formattedAmounts = {
		[independentField]: typedValue,
		[dependentField]: showWrap
			? parsedAmounts[independentField]?.toExact() ?? ''
			: parsedAmounts[dependentField]?.toSignificant(6) ?? ''
	}

	const route = trade?.route
	const userHasSpecifiedInputOutput = Boolean(
		currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
	)
	const noRoute = !route

	// check whether the user has approved the router on the input token
	const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)

	// check if user has gone through approval process, used to show two step buttons, reset on token change
	const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

	// mark when a user has submitted an approval, reset onTokenSelection for input field
	useEffect(() => {
		if (approval === ApprovalState.PENDING) {
			setApprovalSubmitted(true)
		}
	}, [approval, approvalSubmitted])

	const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
	const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

	// the callback to execute the swap
	const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
		trade,
		allowedSlippage,
		deadline,
		recipient
	)

	const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

	const handleSwap = useCallback(() => {
		if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
			return
		}
		if (!swapCallback) {
			return
		}
		setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
		swapCallback()
			.then(hash => {
				setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: hash })
			})
			.catch(error => {
				setSwapState({
					attemptingTxn: false,
					tradeToConfirm,
					showConfirm,
					swapErrorMessage: error.message,
					txHash: undefined
				})
			})
	}, [tradeToConfirm, priceImpactWithoutFee, showConfirm, swapCallback])

	// errors
	const [showInverted, setShowInverted] = useState<boolean>(false)

	// warnings on slippage
	const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

	// show approve flow when: no error on inputs, not approved or pending, or approved in current session
	// never show if price impact is above threshold in non expert mode
	const showApproveFlow =
		!swapInputError &&
		(approval === ApprovalState.NOT_APPROVED ||
			approval === ApprovalState.PENDING ||
			(approvalSubmitted && approval === ApprovalState.APPROVED)) &&
		!(priceImpactSeverity > 3 && !isExpertMode)

	const handleConfirmDismiss = useCallback(() => {
		setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
		// if there was a tx hash, we want to clear the input
		if (txHash) {
			onUserInput(Field.INPUT, '')
		}
	}, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

	const handleAcceptChanges = useCallback(() => {
		setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
	}, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

	const handleInputSelect = useCallback(
		inputCurrency => {
			setApprovalSubmitted(false) // reset 2 step UI for approvals
			onCurrencySelection(Field.INPUT, inputCurrency)
		},
		[onCurrencySelection]
	)

	const handleMaxInput = useCallback(() => {
		maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact())
	}, [maxAmountInput, onUserInput])

	const handleOutputSelect = useCallback(outputCurrency => onCurrencySelection(Field.OUTPUT, outputCurrency), [
		onCurrencySelection
	])

	const { ethereum } = window
	const handleAddPndaToMM = useCallback(() => addTokenToMetamask(ethereum, PNDA), [])
	const isPndaSelected =
		currencies[Field.INPUT]?.symbol === PNDA.symbol || currencies[Field.OUTPUT]?.symbol === PNDA.symbol

	return (
		<>
			<TokenWarningModal
				isOpen={urlLoadedTokens.length > 0 && !dismissTokenWarning}
				tokens={urlLoadedTokens}
				onConfirm={handleConfirmTokenWarning}
			/>
			{isPndaSelected && (
				<ButtonImagePlus
					onClick={() => handleAddPndaToMM()}
					style={{
						width: 'auto',
						position: 'absolute',
						marginTop: '-20px',
						marginRight: isMobile ? '' : '-200px',
						whiteSpace: 'nowrap'
					}}
				>
					Add PNDA to MetaMask
				</ButtonImagePlus>
			)}
			<AppBody>
				<SwapPoolTabs active={'swap'} />
				<Wrapper id="swap-page">
					<ConfirmSwapModal
						isOpen={showConfirm}
						trade={trade}
						originalTrade={tradeToConfirm}
						onAcceptChanges={handleAcceptChanges}
						attemptingTxn={attemptingTxn}
						txHash={txHash}
						recipient={recipient}
						allowedSlippage={allowedSlippage}
						onConfirm={handleSwap}
						swapErrorMessage={swapErrorMessage}
						onDismiss={handleConfirmDismiss}
					/>

					<AutoColumn gap={'md'}>
						<CurrencyInputPanel
							label={independentField === Field.OUTPUT && !showWrap ? 'From (estimated)' : 'From'}
							value={formattedAmounts[Field.INPUT]}
							showMaxButton={!atMaxAmountInput}
							currency={currencies[Field.INPUT]}
							onUserInput={handleTypeInput}
							onMax={handleMaxInput}
							onCurrencySelect={handleInputSelect}
							otherCurrency={currencies[Field.OUTPUT]}
							id="swap-currency-input"
						/>

						<AutoColumn justify="space-between">
							<AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
								<ArrowWrapper clickable>
									<ArrowDown
										size="16"
										onClick={() => {
											setApprovalSubmitted(false) // reset 2 step UI for approvals
											onSwitchTokens()
										}}
										color={currencies[Field.INPUT] && currencies[Field.OUTPUT] ? theme.primary1 : theme.text2}
									/>
								</ArrowWrapper>
								{recipient === null && !showWrap && isExpertMode ? (
									<LinkStyledButton id="add-recipient-button" onClick={() => onChangeRecipient('')}>
										+ Add a send (optional)
									</LinkStyledButton>
								) : null}
							</AutoRow>
						</AutoColumn>
						<CurrencyInputPanel
							value={formattedAmounts[Field.OUTPUT]}
							onUserInput={handleTypeOutput}
							label={independentField === Field.INPUT && !showWrap ? 'To (estimated)' : 'To'}
							showMaxButton={false}
							currency={currencies[Field.OUTPUT]}
							onCurrencySelect={handleOutputSelect}
							otherCurrency={currencies[Field.INPUT]}
							id="swap-currency-output"
						/>

						{recipient !== null && !showWrap ? (
							<>
								<AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
									<ArrowWrapper clickable={false}>
										<ArrowDown size="16" color={theme.text2} />
									</ArrowWrapper>
									<LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
										- Remove send
									</LinkStyledButton>
								</AutoRow>
								<AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
							</>
						) : null}

						{showWrap ? null : (
							<Card padding={'.25rem .75rem 0 .75rem'} borderRadius={'20px'}>
								<AutoColumn gap="4px">
									<RowBetween align="center">
										<Text fontWeight={500} fontSize={14} color={theme.text2}>
											Price
										</Text>
										<TradePrice
											inputCurrency={currencies[Field.INPUT]}
											outputCurrency={currencies[Field.OUTPUT]}
											price={trade?.executionPrice}
											showInverted={showInverted}
											setShowInverted={setShowInverted}
										/>
									</RowBetween>

									{allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
										<RowBetween align="center">
											<ClickableText fontWeight={500} fontSize={14} color={theme.text2} onClick={toggleSettings}>
												Slippage Tolerance
											</ClickableText>
											<ClickableText fontWeight={500} fontSize={14} color={theme.text2} onClick={toggleSettings}>
												{allowedSlippage / 100}%
											</ClickableText>
										</RowBetween>
									)}
								</AutoColumn>
							</Card>
						)}
					</AutoColumn>
					<BottomGrouping>
						{!account ? (
							<ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
						) : showWrap ? (
							<ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap}>
								{wrapInputError ??
									(wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
							</ButtonPrimary>
						) : noRoute && userHasSpecifiedInputOutput ? (
							<GreyCard style={{ textAlign: 'center' }}>
								<TYPE.main mb="4px">Insufficient liquidity for this trade.</TYPE.main>
							</GreyCard>
						) : showApproveFlow ? (
							<RowBetween>
								<ButtonPrimary
									onClick={approveCallback}
									disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
									width="48%"
									altDisbaledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
								>
									{approval === ApprovalState.PENDING ? (
										<Dots>Approving</Dots>
									) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
										'Approved'
									) : (
										'Approve ' + currencies[Field.INPUT]?.symbol
									)}
								</ButtonPrimary>
								<ButtonError
									onClick={() => {
										if (isExpertMode) {
											handleSwap()
										} else {
											setSwapState({
												tradeToConfirm: trade,
												attemptingTxn: false,
												swapErrorMessage: undefined,
												showConfirm: true,
												txHash: undefined
											})
										}
									}}
									width="48%"
									id="swap-button"
									disabled={
										!isValid || approval !== ApprovalState.APPROVED || (priceImpactSeverity > 3 && !isExpertMode)
									}
									error={isValid && priceImpactSeverity > 2}
								>
									<Text fontSize={16} fontWeight={500}>
										{priceImpactSeverity > 3 && !isExpertMode
											? `Price Impact High`
											: `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
									</Text>
								</ButtonError>
							</RowBetween>
						) : (
							<ButtonError
								onClick={() => {
									if (isExpertMode) {
										handleSwap()
									} else {
										setSwapState({
											tradeToConfirm: trade,
											attemptingTxn: false,
											swapErrorMessage: undefined,
											showConfirm: true,
											txHash: undefined
										})
									}
								}}
								id="swap-button"
								disabled={!isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError}
								error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
							>
								<Text fontSize={20} fontWeight={500}>
									{swapInputError
										? swapInputError
										: priceImpactSeverity > 3 && !isExpertMode
										? `Price Impact Too High`
										: `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
								</Text>
							</ButtonError>
						)}
						{isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
						{betterTradeLinkVersion && <BetterTradeLink version={betterTradeLinkVersion} />}
					</BottomGrouping>
				</Wrapper>
			</AppBody>
			<AdvancedSwapDetailsDropdown trade={trade} />
		</>
	)
}
