import React from 'react'
import { Currency, Price } from 'uniswap-bsc-sdk'
import { useContext } from 'react'
import { Repeat } from 'react-feather'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { StyledBalanceMaxMini } from './styleds'

interface TradePriceProps {
	price?: Price
	inputCurrency?: Currency
	outputCurrency?: Currency
	showInverted: boolean
	setShowInverted: (showInverted: boolean) => void
}

export default function TradePrice({
	price,
	inputCurrency,
	outputCurrency,
	showInverted,
	setShowInverted
}: TradePriceProps) {
	const theme = useContext(ThemeContext)

	const formattedPrice = showInverted ? price?.toSignificant(6) : price?.invert()?.toSignificant(6)

	const show = Boolean(inputCurrency && outputCurrency)
	const label = showInverted
		? `${outputCurrency?.symbol} per ${inputCurrency?.symbol}`
		: `${inputCurrency?.symbol} per ${outputCurrency?.symbol}`

	return (
		<Text
			fontWeight={500}
			fontSize={14}
			color={theme.text2}
			style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
		>
			{show ? (
				<>
					{formattedPrice ?? '-'} {label}
					<StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
						<Repeat size={14} />
					</StyledBalanceMaxMini>
				</>
			) : (
				'-'
			)}
		</Text>
	)
}
