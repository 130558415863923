import { JSBI, Pair, Percent } from 'uniswap-bsc-sdk'
import { darken } from 'polished'
import React, { useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Link } from 'react-router-dom'
import { Text } from 'rebass'
import styled from 'styled-components'
import { useTotalSupply } from '../../data/TotalSupply'

import { useActiveWeb3React } from '../../hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { ExternalLink } from '../../theme'
import { currencyId } from '../../utils/currencyId'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { ButtonSecondary } from '../Button'

import Card, { GreyCard } from '../Card'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { AutoRow, RowBetween, RowFixed } from '../Row'
import { Dots } from '../swap/styleds'

export const FixedHeightRow = styled(RowBetween)`
	height: 24px;
`

export const HoverCard = styled(Card)`
	border: 1px solid ${({ theme }) => theme.bg2};
	:hover {
		border: 1px solid ${({ theme }) => darken(0.06, theme.bg2)};
	}
`

interface PositionCardProps {
	pair: Pair
	showUnwrapped?: boolean
	border?: string
}

export function MinimalPositionCard({ pair, showUnwrapped = false, border }: PositionCardProps) {
	const { account } = useActiveWeb3React()

	const currency0 = showUnwrapped ? pair.token0 : unwrappedToken(pair.token0)
	const currency1 = showUnwrapped ? pair.token1 : unwrappedToken(pair.token1)

	const [showMore, setShowMore] = useState(false)

	const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
	const totalPoolTokens = useTotalSupply(pair.liquidityToken)

	const [token0Deposited, token1Deposited] =
		!!pair &&
		!!totalPoolTokens &&
		!!userPoolBalance &&
		// this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
		JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
			? [
					pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
					pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false)
			  ]
			: [undefined, undefined]

	return (
		<>
			{userPoolBalance && (
				<GreyCard border={border}>
					<AutoColumn gap="12px">
						<FixedHeightRow>
							<RowFixed>
								<Text fontWeight={500} fontSize={16}>
									Your position
								</Text>
							</RowFixed>
						</FixedHeightRow>
						<FixedHeightRow onClick={() => setShowMore(!showMore)}>
							<RowFixed>
								<DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin={true} size={20} />
								<Text fontWeight={500} fontSize={20}>
									{currency0.symbol}/{currency1.symbol}
								</Text>
							</RowFixed>
							<RowFixed>
								<Text fontWeight={500} fontSize={20}>
									{userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
								</Text>
							</RowFixed>
						</FixedHeightRow>
						<AutoColumn gap="4px">
							<FixedHeightRow>
								<Text color="#888D9B" fontSize={16} fontWeight={500}>
									{currency0.symbol}:
								</Text>
								{token0Deposited ? (
									<RowFixed>
										<Text color="#888D9B" fontSize={16} fontWeight={500} marginLeft={'6px'}>
											{token0Deposited?.toSignificant(6)}
										</Text>
									</RowFixed>
								) : (
									'-'
								)}
							</FixedHeightRow>
							<FixedHeightRow>
								<Text color="#888D9B" fontSize={16} fontWeight={500}>
									{currency1.symbol}:
								</Text>
								{token1Deposited ? (
									<RowFixed>
										<Text color="#888D9B" fontSize={16} fontWeight={500} marginLeft={'6px'}>
											{token1Deposited?.toSignificant(6)}
										</Text>
									</RowFixed>
								) : (
									'-'
								)}
							</FixedHeightRow>
						</AutoColumn>
					</AutoColumn>
				</GreyCard>
			)}
		</>
	)
}

export default function FullPositionCard({ pair, border }: PositionCardProps) {
	const { account } = useActiveWeb3React()

	const currency0 = unwrappedToken(pair.token0)
	const currency1 = unwrappedToken(pair.token1)

	const [showMore, setShowMore] = useState(false)

	const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
	const totalPoolTokens = useTotalSupply(pair.liquidityToken)

	const poolTokenPercentage =
		!!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
			? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
			: undefined

	const [token0Deposited, token1Deposited] =
		!!pair &&
		!!totalPoolTokens &&
		!!userPoolBalance &&
		// this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
		JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
			? [
					pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
					pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false)
			  ]
			: [undefined, undefined]

	return (
		<HoverCard border={border}>
			<AutoColumn gap="12px">
				<FixedHeightRow onClick={() => setShowMore(!showMore)} style={{ cursor: 'pointer' }}>
					<RowFixed>
						<DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin={true} size={20} />
						<Text fontWeight={500} fontSize={20}>
							{!currency0 || !currency1 ? <Dots>Loading</Dots> : `${currency0.symbol}/${currency1.symbol}`}
						</Text>
					</RowFixed>
					<RowFixed>
						{showMore ? (
							<ChevronUp size="20" style={{ marginLeft: '10px' }} />
						) : (
							<ChevronDown size="20" style={{ marginLeft: '10px' }} />
						)}
					</RowFixed>
				</FixedHeightRow>
				{showMore && (
					<AutoColumn gap="8px">
						<FixedHeightRow>
							<RowFixed>
								<Text fontSize={16} fontWeight={500}>
									Pooled {currency0.symbol}:
								</Text>
							</RowFixed>
							{token0Deposited ? (
								<RowFixed>
									<Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
										{token0Deposited?.toSignificant(6)}
									</Text>
									<CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency0} />
								</RowFixed>
							) : (
								'-'
							)}
						</FixedHeightRow>

						<FixedHeightRow>
							<RowFixed>
								<Text fontSize={16} fontWeight={500}>
									Pooled {currency1.symbol}:
								</Text>
							</RowFixed>
							{token1Deposited ? (
								<RowFixed>
									<Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
										{token1Deposited?.toSignificant(6)}
									</Text>
									<CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency1} />
								</RowFixed>
							) : (
								'-'
							)}
						</FixedHeightRow>
						<FixedHeightRow>
							<Text fontSize={16} fontWeight={500}>
								Your pool tokens:
							</Text>
							<Text fontSize={16} fontWeight={500}>
								{userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
							</Text>
						</FixedHeightRow>
						<FixedHeightRow>
							<Text fontSize={16} fontWeight={500}>
								Your pool share:
							</Text>
							<Text fontSize={16} fontWeight={500}>
								{poolTokenPercentage ? poolTokenPercentage.toFixed(2) + '%' : '-'}
							</Text>
						</FixedHeightRow>

						<AutoRow justify="center" marginTop={'10px'}>
							<ExternalLink href={`https://info.pandaswap.xyz/pair/${pair.liquidityToken.address}`}>
								View pool information ↗
							</ExternalLink>
						</AutoRow>
						<RowBetween marginTop="10px">
							<ButtonSecondary as={Link} to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`} width="48%">
								Add
							</ButtonSecondary>
							<ButtonSecondary as={Link} width="48%" to={`/remove/${currencyId(currency0)}/${currencyId(currency1)}`}>
								Remove
							</ButtonSecondary>
						</RowBetween>
					</AutoColumn>
				)}
			</AutoColumn>
		</HoverCard>
	)
}
